// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-voucher-404-js": () => import("./../../../src/pages/voucher/404.js" /* webpackChunkName: "component---src-pages-voucher-404-js" */),
  "component---src-pages-voucher-index-js": () => import("./../../../src/pages/voucher/index.js" /* webpackChunkName: "component---src-pages-voucher-index-js" */),
  "component---src-pages-weapons-common-js": () => import("./../../../src/pages/weapons/common.js" /* webpackChunkName: "component---src-pages-weapons-common-js" */),
  "component---src-pages-weapons-epic-js": () => import("./../../../src/pages/weapons/epic.js" /* webpackChunkName: "component---src-pages-weapons-epic-js" */),
  "component---src-pages-weapons-rare-js": () => import("./../../../src/pages/weapons/rare.js" /* webpackChunkName: "component---src-pages-weapons-rare-js" */),
  "component---src-pages-weapons-uncommon-js": () => import("./../../../src/pages/weapons/uncommon.js" /* webpackChunkName: "component---src-pages-weapons-uncommon-js" */)
}

